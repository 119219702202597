<template>
  <div>
    <el-dialog title="选择模板类型"
               :visible.sync="dialogVisible"
               v-dialogDrag
               :close-on-click-modal="false"
               :append-to-body="true"
               width="500px"
               :before-close="handleClose">
      <el-form label-width="120px">
        <el-row :gutter="10">
          <el-col :span="24">
            <el-form-item label="模板类型">
              <!-- <div>
                <el-radio v-model="tipType"
                          label="0"
                          border>通用模板</el-radio>
              </div> -->
              <div>
                <el-radio v-model="tipType"
                          v-if="pageType=='autonomously'"
                          label="1"
                          :disabled="!templateLen"
                          border>推荐模板<span v-if="!templateLen">(暂无)</span></el-radio>
              </div>
              <div>
                <el-radio v-model="tipType"
                          label="2"
                          border>自制模板</el-radio><br />
              </div>

            </el-form-item>
          </el-col>
        </el-row>

        <el-row :gutter="10"
                style="text-align:center">
          <el-col :span="24">
            <el-button type="primary"
                       @click="submit">确定</el-button>
          </el-col>
        </el-row>
      </el-form>

    </el-dialog>
  </div>
</template>

<script>
import getFile from '@/utils/file.js'

export default {
  data () {

    return {
      dialogVisible: false,
      tipType: '2',//0通用，1推荐，2自制
      pageType: '',
      templateLen: 0

    }
  },
  methods: {
    handleClose () {
      this.dialogVisible = false
    },
    submit () {
      this.$emit("changeType", this.tipType)
    },


  }
}
</script>

<style lang="scss" scoped>
.hide_label {
  ::v-deep .el-radio__label {
    display: none;
  }
}

::v-deep .el-dialog__header {
  font-size: 20px;
  font-weight: bold;
  color: black;
  text-align: center;
}

::v-deep .el-dialog__headerbtn .el-dialog__close {
  color: white;
  background: #cccccc;
  border-radius: 50%;
  padding: 4px;
}
::v-deep.el-radio {
  color: #606266;
  cursor: pointer;
  margin-right: 30px;
  margin-bottom: 10px;
}
</style>